import "./zoom-wrapper.scss";
import React from "react";

const ZoomWrapper = ({ onClick }) => {
  return (
    <div className="zoom-wrapper">
      <div className="zoom-wrapper__bg" onClick={onClick} aria-hidden="true" />
      <span
        className="zoom-wrapper__zoom-icon"
        onClick={onClick}
        aria-hidden="true"
      />
    </div>
  );
};

export default ZoomWrapper;
