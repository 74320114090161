import "styles/pages/page-gallery.scss";

import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import ZoomWrapper from "components/ZoomWrapper";
import Breadcrumps from "components/Breadcrumps";

const PageGallery = ({ pageContext }) => {
  const seo = pageContext.seo;
  const pageData = pageContext.data.pageGallery;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const gallery = pageData.gallery?.map((item) => item.galleryImage?.sourceUrl);

  const backgroundImage = [];
  function createBackgroundImageVariables() {
    for (let i = 0; i <= 19; ++i) {
      backgroundImage[i] = {
        backgroundImage: `url(${pageData.gallery?.[i].galleryImage?.sourceUrl})`,
      };
    }
  }
  createBackgroundImageVariables();

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={pageContext.data.title} />
      <SubpageHeader title={pageContext.data.title} />
      <section className="gallery-info">
        <div className="container">
          <h2 className="section-title">{pageData.title}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div
            className="gallery-info__text"
            dangerouslySetInnerHTML={{ __html: pageData.description }}
          />
        </div>
      </section>
      <section className="gallery">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--1"
                style={backgroundImage[0]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(0);
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="gallery__img gallery__img--small gallery__img--2"
                style={backgroundImage[1]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(1);
                  }}
                />
              </div>
              <div
                className="gallery__img gallery__img--small gallery__img--3"
                style={backgroundImage[2]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(2);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--4"
                style={backgroundImage[3]}
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(3);
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="gallery__img gallery__img--big gallery__img--5"
                style={backgroundImage[4]}
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(4);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--6"
                style={backgroundImage[5]}
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(5);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--7"
                style={backgroundImage[6]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(6);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--8"
                style={backgroundImage[7]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(7);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="gallery__img gallery__img--big gallery__img--9"
                style={backgroundImage[8]}
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(8);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="gallery__img gallery__img--big gallery__img--10"
                style={backgroundImage[9]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(9);
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="gallery__img gallery__img--small gallery__img--11"
                style={backgroundImage[10]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(10);
                  }}
                />
              </div>
              <div
                className="gallery__img gallery__img--small gallery__img--12"
                style={backgroundImage[11]}
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(11);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--13"
                style={backgroundImage[12]}
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(12);
                  }}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="gallery__img gallery__img--big gallery__img--14"
                style={backgroundImage[13]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(13);
                }}
                aria-hidden="true"
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(3);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--15"
                style={backgroundImage[14]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(14);
                }}
                aria-hidden="true"
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(14);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="gallery__img gallery__img--big gallery__img--16"
                style={backgroundImage[15]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(15);
                }}
                aria-hidden="true"
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(15);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="gallery__img gallery__img--big gallery__img--17"
                style={backgroundImage[16]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(16);
                }}
                aria-hidden="true"
              >
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(16);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--17"
                style={backgroundImage[17]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(17);
                }}
                aria-hidden="true"
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(17);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--17"
                style={backgroundImage[18]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(18);
                }}
                aria-hidden="true"
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(18);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="gallery__img gallery__img--big gallery__img--17"
                style={backgroundImage[19]}
                onClick={(e) => {
                  e.preventDefault();
                  setIsLightboxOpen(true);
                  setPhotoIndex(19);
                }}
                aria-hidden="true"
              >
                {" "}
                <ZoomWrapper
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLightboxOpen(true);
                    setPhotoIndex(19);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </Layout>
  );
};

export default PageGallery;
